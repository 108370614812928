export const environmentProd = {
  apiUrl: {
    baseApiUrl: "https://emea.studiogateway.chubb.com/claims.vendormanagement",
    baseApiUrlAssignment: "https://emea.studiogateway.chubb.com/claims.vendorassignment",
    baseApiUrlCommonRef: "https://emea.studiogateway.chubb.com/other.claims.cogcommonreference",
    baseApiUrlAppCommonRef: "https://emea.studiogateway.chubb.com/other.claims.cogcommonreference",
    baseApiUrlDocMgnt: "https://emea.studiogateway.chubb.com/claims.cogdocumentmanagement",
    baseApiUrlVendorAdb2c: "https://na.chubbdigital.com",
    newVendorRegistrationURL: "https://vendorassignment.chubb.com/vendorlogin/registration/organization-name?ui_locales={{**}}"
  },
  authProviderConfig: {
    authProvider: "MSAL2WithEvents",
    tenant: "auth.chubb.com",
    clientId: "fbbd8761-9066-4f00-84dd-122289a8b831",
    policy: "B2C_1A_COGVENDOR_SIGNIN",
    redirectUrl: "https://vendorassignment.chubb.com",
    scopes: [
      {
        scope: "https://b2c.chubbdigital.com/COGVendorAssignmentAPI/write"
      },
      {
        scope: "https://b2c.chubbdigital.com/COGVendorAssignmentAPI/read"
      }
    ],
    queryParameters: [
      {
        key: "domain_hint",
        value: "CHUBBgroup"
      }
    ]
  },
  jwt: {
    common: {
      url: "https://emea.studiogateway.chubb.com/enterprise.operations.authorization?Identity=AAD",
      apP_ID: "a3e5ee05-a126-4b12-95b5-97b4ae700a3c",
      apP_KEY: "HI.s47_Z7Zawj3~VI0hQNRe2VK.3iIp8gu",
      resource: "24a83757-18e9-4319-b8f4-3b27de39970b",
      ocpApimSubscriptionKey: "65475a8c9eac4cdeab6f81901b3894b9",
      apiVersion: "2"
    },
    cogcommon: {
      url: "https://emea.studiogateway.chubb.com/enterprise.operations.authorization?Identity=AAD",
      apP_ID: "7a7e2462-004d-4496-9407-c61e548ec50a",
      apP_KEY: ".oyqR3.-oq~_hMur1iB_l76BVkz2P.IV5t",
      resource: "cbf64b88-6366-4fa6-b792-6ef5e4cc3b5a",
      ocpApimSubscriptionKey: "bd14841e19904d448ae5388362033b8d",
      apiVersion: "1"
    },
    cogvendormanager: {
      url: "https://emea.studiogateway.chubb.com/enterprise.operations.authorization?Identity=AAD",
      apP_ID: "2dbf2c02-3e6a-451b-91a2-697fb44ca3cc",
      apP_KEY: "SLe.1lwes5.T0M.Ps9uhnVUloz4Ro~Oh7Z",
      resource: "d6088aee-3e34-44fa-806b-2e08adc0c335",
      ocpApimSubscriptionKey: "72d0e63708d047a19a1084cf307927ba",
      apiVersion: "1"
    },
    cogvendorassignment: {
      url: "https://emea.studiogateway.chubb.com/enterprise.operations.authorization?Identity=AAD",
      apP_ID: "0cd210d6-4328-42a3-bee6-17e69f94403f",
      apP_KEY: "UeiFKKc25Ql.4Ils7.KszZC-M88_A39~~d",
      resource: "99f45297-a08c-4111-9f10-58610eb78111",
      ocpApimSubscriptionKey: "9c33ff1f61c64a7bb984802abeb3fbbe",
      apiVersion: "1"
    },
    cogdocumentmanagement: {
      url: "https://emea.studiogateway.chubb.com/enterprise.operations.authorization?Identity=AAD",
      apP_ID: "3331a6bb-e0b4-48d0-817e-411e061f2828",
      apP_KEY: "dDN2g0kI_~Tdt56_P2PD_Vs.YzQCKV-U7Y",
      resource: "14da6e99-2ecc-495b-80b1-5b88688a1898",
      ocpApimSubscriptionKey: "0efd43b6c68a400b88d7a2638a360060",
      apiVersion: "1"
    },
    cogadb2cvendor: {
      url: "",
      apP_ID: "",
      apP_KEY: "",
      resource: "",
      ocpApimSubscriptionKey: "086d77fd7623413c827778621cc72f17",
      apiVersion: ""
    },
    cogadb2adgroup: {
      url: "",
      apP_ID: "",
      apP_KEY: "",
      resource: "",
      ocpApimSubscriptionKey: "086d77fd7623413c827778621cc72f17",
      apiVersion: ""
    }
  },
  appConstants: {
    parsingTeamEmail: "COGparsing@chubb.com"
  }
}
